export const configureManagementActions = (managementActionsBuilder, t) => {
  managementActionsBuilder.mainActions().addAction(
    {
      title: t('Management_Actions_Add_New_Project'),
      actionId: 'addNewProject',
      icon: 'addAction',
    },
    {
      title: t('Management_Actions_Manage_Projects'),
      actionId: 'manageProjects',
      icon: 'settingsAction',
    },
  );
  managementActionsBuilder.customActions().addAction({
    title: t('Management_Actions_Create_New_Category'),
    actionId: 'addNewCategory',
    icon: 'addAction',
    type: 'dashboard',
  });

  managementActionsBuilder.learnMoreAction().set({
    id: '3478478-394839489-39849358',
  });
};
