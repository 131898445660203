import { EditorSDK, AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { configurePages, configPagesTabs } from './manifest/configurePages';
import { configureManagementActions } from './manifest/configureManagementActions';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export const modifyAppManifest = async (
  options,
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;
  const baseManifest = await fetchWidgetsStageData(options);
  const pages = (pagesBuilder) => configurePages(pagesBuilder, t);
  const pagesTab = (pagesTabBuilder) => configPagesTabs(pagesTabBuilder, t);
  const managementActions = (managementActionsBuilder) =>
    configureManagementActions(managementActionsBuilder, t);

  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePages(pages)
    .configurePagesTab(pagesTab)
    .configureManagementActions(managementActions);
  return appManifestBuilder.build();
};
