export enum ProjectHeaderWidgetComponentIds {
  Root = '#projectHeaderWidgetRoot',
  Title = '#projectTitle',
  SubTitle = '#projectSubtitle',
  Description = '#projectDescription',
  HeroImage = '#projectHeroImage',
}

export enum ProjectHeaderWidgetEvents {}
// Change = 'change',
const presets = [
  {
    name: 'A',
    id: 'variants-kzwjjh6z1',
  },
  {
    name: 'B',
    id: 'variants-l0oexwaf',
  },
  {
    name: 'C',
    id: 'variants-l0of3zoh',
  },
  {
    name: 'D',
    id: 'variants-l0ozyn1y',
  },
  {
    name: 'E',
    id: 'variants-l0p0beoe',
  },
  {
    name: 'F',
    id: 'variants-l0p6ekc3',
  },
  {
    name: 'G',
    id: 'variants-l0p7axuw',
  },
];
export const ProjectHeaderWidgetPresets = presets.map((preset) => ({
  id: preset.id,
  src:
    'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/tropical-background-%2Csummer-background-design-template-77d4868da55f9006156f1cd3d087c8e4_screen.jpg?ts=1594231813',
  layout: {
    width: '250px',
    height: '130px',
  },
}));
