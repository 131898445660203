import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { CategoriesGalleryWidgetData } from './config/types';
import { CategoriesGalleryWidgetEvents as Events } from './config/constants';

const DEFAULT_DATA = {
  categories: [],
} as CategoriesGalleryWidgetData;

export default createBlocksModel({
  widgetName: 'CategoriesGalleryWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Categories Gallery Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Categories gallery portfoio page',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
