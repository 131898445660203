import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  controllerBuilder.set({
    displayName: 'Categories List Widget',
    nickname: 'categoriesListWidget',
  });
};
