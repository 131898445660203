const PORTFOLIO_APP_DEF_ID = 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130';

export async function setPortfolioPagesState(editorSDK: any) {
  const applicationPages = await editorSDK.document.pages.getApplicationPages(
    '',
  );
  const portfolioPages = applicationPages.filter(
    ({ managingAppDefId }) => managingAppDefId === PORTFOLIO_APP_DEF_ID,
  );
  portfolioPages.forEach((pageData) => {
    editorSDK.document.pages.setState('', {
      state: {
        [pageData.tpaPageId]: [{ id: pageData.id }],
      },
    });
  });
}
