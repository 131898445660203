export enum NavigationWidgetComponentIds {
  Root = '#navigationRoot',
  NextContainer = '#nextContainer',
  NextText = '#nextText',
  NextButton = '#nextButton',
  BackContainer = '#backContainer',
  BackText = '#backText',
  BackButton = '#backButton',
}

export enum NavigationWidgetEvents {
  nextClicked = 'nextClicked',
  backClicked = 'backClicked',
}
// Change = 'change',
