import { AppPageData } from '../types/types';

export enum PortfolioPagesRole {
  Portfolio = 'portfolio',
  Category = 'category',
  Project = 'project',
  Tag = 'tag',
}
interface IAppPageData {
  [key: string]: AppPageData;
}

export const Pages: IAppPageData = {
  PORTFOLIO_PAGE: {
    pageId: 'Portfolio',
    title: 'Portfolio_Page_Title',
    widgetId: 'ccd857ff-153e-452c-8cea-3f3a8aafeb86',
    presetId: 'variants-kzve32d0',
    pageUriSEO: 'portfolio',
  },
  CATEGORY_PAGE: {
    pageId: 'Category',
    title: 'Category_Page_Title',
    widgetId: 'd6c045f0-5d83-4c6c-845e-9059ca159990',
    presetId: 'variants-kzwjby151',
    pageUriSEO: 'category',
  },
  PROJECT_PAGE: {
    pageId: 'Project',
    title: 'Project_Page_Title',
    widgetId: '437cf52e-f57d-449e-af48-96791677e471',
    presetId: 'variants-kzwh8ghy1',
    pageUriSEO: 'project',
  },
  TAG_PAGE: {
    pageId: 'Tag',
    title: 'Tag_Page_Title',
    widgetId: 'e0a0078d-0b7b-448b-8286-634343625929',
    presetId: 'variants-kzwh8t9n1',
    pageUriSEO: 'tag',
  },
};
