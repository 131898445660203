export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiState = '#portfolioMultiStateBox',
  GalleryWidget = '#categoriesGalleryWidget',
  ListWidget = '#categorieListWidget',
  CategoryWidget = '#categoryWidget',
}

export enum PortfolioPageWidgetStates {
  Gallery = 'galleryState',
  List = 'listState',
  Category = 'categoryState',
}

export enum PortfolioPageWidgetEvents {
  // Change = 'change',
}
