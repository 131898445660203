export const configurePages = (pagesBuilder, t) => {
  // the actions for each page
  pagesBuilder.set({
    icon: 'page',
  });
  // specific page actions according to page state
  // pagesBuilder.configureState('Portfolio', (stateBuilder) => {
  //   // tooltip for a page
  //   stateBuilder.set({
  //     icon: 'page',
  //     tooltip: 'This Is The Portfolio Page',
  //     orderIndex: 1,
  //   });
  //   stateBuilder.addAction({
  //     title: 'Delete Portfolio Page',
  //     event: 'deletePortfolioPage',
  //     icon: 'delete',
  //   });
  // });

  // the tab settings for each page that opens when clicking settings/seo.. tab
  pagesBuilder.addSettingsTab(
    {
      title: t('Page_Builder_Settings_Tab_Page_Info'), // each one is a tab in the page settings
      type: 'page_info',
    },
    {
      title: t('Page_Builder_Settings_Tab_Page_Layout'),
      type: 'layout',
    },
    {
      title: t('Page_Builder_Settings_Tab_Page_Permissions'),
      type: 'permissions',
    },
  );
};
export const configPagesTabs = (pagesTabBuilder, t) => {
  // application page settings
  pagesTabBuilder
    .set({
      // displayName: 'Portfolio pages',
      helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
    })
    .setGrouping('none');

  // application page settings actions
  pagesTabBuilder.addAction({
    title: t('Page_Builder_Settings_Tab_Action_Delete_App'),
    icon: 'delete',
    event: 'deletePortfolioApp',
  });
};
