export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  MultiStateBox = '#projectMultiStateBox',
  ProjectWidget1 = '#projectWidget1',
  ProjectWidget2 = '#projectWidget2',
  ProjectWidget3 = '#projectWidget3',
}

export enum ProjectPageWidgetStates {
  STATE_1 = '1',
  STATE_2 = '2',
  STATE_3 = '3',
}

export enum ProjectPageWidgetEvents { }

export const ProjectPageWidgetPresets = [
  {
    id: 'externalPreset-Template1',
    src:
      'https://lh3.googleusercontent.com/fife/AAWUweWsPRKTjjoL9iSrO2z5pjCw2z4pQKgnkLIfJsdE8-ljwiz0w2flTN3Yw25_0Zx1kyuCQucrK3O6ATVVzRJAJ1wE5W4cN-QOpvftAhVzvMiecvHj_DLjMDjq12qG7BcjH4h9m40DjliKxV6NI6VJ-rdgNXvKehi1mHEqRIVv8da_jwCOQb1W7BhmYWh83uLMIG54yyuooxKfRGPAiRS5UCU1tbAwZQV4-aIOVUTgjzWKnjpt2CjQZzgqaLyzh7hcnoirZCxJV7O5plNBK3kdF_WmRZgEdR_fyaEJCXBP_4E0Qp5PeA3wYsjL1VC0SHkHDxU4oTGLsaEDnlQdmFms9WbEFwOuHNEsX9WsHABWLzj4ayYiUZTWC06Oc9kyJJ44qACt5biZ_DJ_xS4HoMBBNDxBb1PnG4f83KCS2fMRYS5_RmXIo4kmqdbNu081zx6XTMWcIUst-YYvYdVOfw8YxvgQUNZwqBciP8xJwe1DMQZfI3CNHUQ-WuD8cxDbaCYnuSRlXKtCkymvIDuob-5LcPSU_TqI_XKB0BIzO2TbaF9t-oSKIFcDUTaiTTwIgNqkVJ9YYz_5vASB26i36Xi8IySxX7xPMcpSZLbmAzU66q-r4Z7pd1XelR897-ZEo4qo1Yc4Iw5vos8Xw_1yGgYkNAEIt0WUkYJhSABMQd7AS_VXYUEmPdUN_TzTvEx6RyPfIGKaUN-5WIGlb0ZlLHjyglx9WRk1Qrw06KLn_YDUjrCgbCplGpd2bR8-UdFkZzJCGWZj6MOnuI5DqLmiQUx-sw=w250',
    layout: {
      width: '250px',
      height: '130px',
    },
  },
  {
    id: 'externalPreset-Template2',
    src:
      'https://lh3.googleusercontent.com/fife/AAWUweVxulpKF2Ptub05rGCmv1yi_AyKmxvZjhjcWnI2IDRNLcXE50Z7o61lzljFd5JEkvnD4-wBQIW0gS6Q_ziq4aZL2Vc6qYk_7GpQ84qsCIWQ6_IrVbTFUOMSWaIafDc3yfjwTSUNBumMV2l0ApD_397bPpUGdylWdgKXHTVbjZJbKU_sPntLA9U8NcKUwr48JzyDd8Brgbqlg8osP8cnDxTp4MiReFJe9lej13YcCChhdRGlWJLwW4DSu0skf4sM9-xo2p8JvC3aEV_8iRT9_1p_2tDyWnfZfHaDj28hQRfBogxaYfXprt0O88k7XHYyHS7kipdUn9pgRRf_363RxwwYDwakLGERDTe2urjPiGaRdu1-6RB8EXU59MoRH8P9KCnH5Ra7hL0NkXx24_X3h5l_2m2l5FyThwiXS-CI0atwslV8qOYveiNZXliwgMVZ1x_0BQOjUGj3VEjTjj-iWQvuuSYZNfFvQkm8pcPqSXqXiJg_dNYn2omLHtwvBFqs93E403wpWzStZm87PH_P0ioNSq9u0Fr0E0v88geQjyzvxr8W-tH-uiDFmfPXetKILwJElNkOO_zVIEbWSMmmDbzlDPwscO9EeSdOpL-r7hFYFamk68S7A3l6blfRPq_vTftTf4uQUUw34aSiLneZa6EzhSASluziXL0UlcrUoQ1ElIoqvJxdZdoK7yCR8XvnH67Bil6oAMIpcOVGrsQImgxIsf8b9HQDMmySjIvIt9V9GnNqEX9ydfkpHk_zPzULI4o4SUgH0oIDCj42IkcgUA=w250',
    layout: {
      width: '250px',
      height: '130px',
    },
  },
  {
    id: 'externalPreset-Template3',
    src:
      'https://lh3.googleusercontent.com/fife/AAWUweUSCJjYiq-xL8GNAjIw0nIa4UmdO_v7iWtTqNyKU2qecy4Y2BNJiCHuAA8VCiztv2G92WFAlJD1w8CqhS4JDeAkDMWBuofoq_u92ivxKboldgwFF4P015pAH6E_qQKVz9Az7EW0orwMi9B6HnMFMlBXZbok6pGwqJ4RxUwDEJN093HehPLqwLICQ95WmBsnEu5DZudQNaW2F73cRPooaBQY5UdqD84BvFUsJDUzCcAIgKxXD_N4IgW3KIqwCA3JPTST-2EMjv3PRjCr3YV8n4QyObpJmmb9Hx4tweyDwXSW05LPZkGZq2eoXO2f4_qxELznjmlLiPKZL6i-tFpz4lxRsvzsZWCbD9g4wO3wdLTX1oiCetFrl5S_4AL4IeWNo6AS4mZP6h5mVFxOnT06EmZZAZE1RhkOwxb7oCsrdJoM0UwtttkvDVAnjW80LiulyXj904MxPdKDlnEsiAwWK2JARPHq0yHTzgWdPER3vRHTV9YsCKFXLb6vzL98x5b2CwOyahhMGV6arf7RlHORVXQ5tPLwWpacCOWSbqUsVd-ct20q0a6nxI7QgxliqugJKT_t4QpdOE-_1_WMLyaK6V_b_F05tHEVaNvhRZ7YJHMXbUV_V2FQEg5LCi5bbvNreG5v9GRUk4cPbh5s5vQcEI1-6fd7H9Vexr2peuaHgFF6OtEn4K7JTZEVFA9l6rN8Dw67dwQWgq7-xwkUCIyOJG0lwfUAueFoYRhVvCNYbc_UUeoVaMz1WHq4XCzV305_-vJ9zYWmHhuaqpz6hPXjPg=w250',
    layout: {
      width: '250px',
      height: '130px',
    },
  },
];
