import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { NavigationWidgetData } from './config/types';
import { NavigationWidgetEvents as Events } from './config/constants';

const DEFAULT_DATA = {
  hideBack: true,
  hideNext: true,
} as NavigationWidgetData;

export default createBlocksModel({
  widgetName: 'NavigationWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Navigation Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for navigation widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    [Events.nextClicked]: {
      description: 'Fired when next button clicked',
    },
    [Events.backClicked]: {
      description: 'Fired when back button clicked',
    },
  },
});
