import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PortfolioPageWidgetData } from './config/types';
import { PortfolioPageWidgetEvents as Events, PortfolioPageWidgetStates as States } from './config/constants';

const DEFAULT_DATA = {
  selectedState: States,
} as PortfolioPageWidgetData;

export default createBlocksModel({
  widgetName: 'PortfolioPageWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Portfolio Page Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for portfoio page',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    change: {
      title: 'Custom Text Fields Changed',
      description: '',
    },
  },
});
