import type {
  EditorSDK,
  EditorReadyOptions,
  PageRef,
  RouterRef,
} from '@wix/platform-editor-sdk';
import { Pages, PortfolioPagesRole } from './const/pagesConsts';
import { createAppPage, initPortfolioPrefix } from './utils/installationUtils';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

const addPageToRouter = async (
  editorSDK: EditorSDK,
  routerRef: RouterRef,
  pageRef: PageRef,
  pageRoles: string,
): Promise<void> => {
  await editorSDK.document.routers.pages.connect('', {
    pageRef,
    routerRef,
    pageRoles: [pageRoles],
  });
};

export async function handleFirstInstall(
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  const { t } = flowAPI.translations;
  const isResponsive = options.origin.type === 'RESPONSIVE';
  const portfolioRouterRef = await initPortfolioPrefix(editorSDK);
  const portfolioPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PORTFOLIO_PAGE,
    isResponsive,
    shouldAddMenuItem: true,
    shouldNavigateToPage: true,
    t,
  });
  await addPageToRouter(
    editorSDK,
    portfolioRouterRef,
    portfolioPageRef,
    PortfolioPagesRole.Portfolio,
  );
  const categoryPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.CATEGORY_PAGE,
    isResponsive,
    t,
  });
  await addPageToRouter(
    editorSDK,
    portfolioRouterRef,
    categoryPageRef,
    PortfolioPagesRole.Category,
  );
  const projectPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PROJECT_PAGE,
    isResponsive,
    t,
  });
  await addPageToRouter(
    editorSDK,
    portfolioRouterRef,
    projectPageRef,
    PortfolioPagesRole.Project,
  );
  // will be open later
  // const tagPageRef = await createAppPage({
  //   editorSDK,
  //   appDefId,
  //   pageData: Pages.TAG_PAGE,
  //   isResponsive,
  // });
  // await addPageToRouter(
  //   editorSDK,
  //   portfolioRouterRef,
  //   tagPageRef,
  //   PortfolioPagesRole.Tag,
  // );
}
