import {} from '../../projectWidget3/config/constants';

const COMPS_DATA = {
  projectTitle: { role: 'projectTitle', label: 'Project title' },
  subtitle: { role: 'projectSubtitle', label: 'Project subtitle' },
  description: { role: 'projectDescription', label: 'Project description' },
  projectHeroImage: { role: 'projectHeroImage', label: 'Project Image' },
  projectNavigation: { role: 'navigationWidget3', label: 'Project Navigation' },
  projectGallery: { role: 'projectGallery3', label: 'Project Gallery' },
};

const CATEGORIES_IDS = {
  info: 'Project Info',
  gallery: 'Project Gallery',
  ctaButtons: 'Project Buttons',
};

export const categoriesData = [
  {
    id: CATEGORIES_IDS.info,
    title: CATEGORIES_IDS.info,
  },
  {
    id: CATEGORIES_IDS.gallery,
    title: CATEGORIES_IDS.gallery,
  },
  {
    id: CATEGORIES_IDS.ctaButtons,
    title: CATEGORIES_IDS.ctaButtons,
  },
];

export const elementsData = [
  {
    label: COMPS_DATA.projectTitle.label,
    identifier: { role: COMPS_DATA.projectTitle.role },
    categoryId: CATEGORIES_IDS.info,
    index: 0,
  },
  {
    label: COMPS_DATA.subtitle.label,
    identifier: { role: COMPS_DATA.subtitle.role },
    categoryId: CATEGORIES_IDS.info,
    index: 1,
  },
  {
    label: COMPS_DATA.description.label,
    identifier: { role: COMPS_DATA.description.role },
    categoryId: CATEGORIES_IDS.info,
    index: 2,
  },
  {
    label: COMPS_DATA.projectHeroImage.label,
    identifier: { role: COMPS_DATA.projectHeroImage.role },
    categoryId: CATEGORIES_IDS.gallery,
    index: 3,
  },
  {
    label: COMPS_DATA.projectGallery.label,
    identifier: { role: COMPS_DATA.projectGallery.role },
    categoryId: CATEGORIES_IDS.gallery,
    index: 4,
  },
  {
    label: COMPS_DATA.projectNavigation.label,
    identifier: { role: COMPS_DATA.projectNavigation.role },
    categoryId: CATEGORIES_IDS.ctaButtons,
    index: 5,
  },
];
